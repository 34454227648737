import { ComponentProps } from 'react';

import TMIcon from 'tm-icons-library';

import { HomeRoutes } from '../types/routes';
import { CustomApp } from './HostMetadata';
import { OnboardingStatus } from './userTypes';

export interface IResponseType<T> {
  code: number;
  message: string;
  result: T;
  type: string;
}

export interface ITokens {
  refreshToken: string;
  accessToken: string;
}

export type DRMTypes = 'Widevine' | 'PlayReady' | 'FairPlay';

export interface IDisabledNotificationStates {
  disableEmail: boolean;
  disableWhatsapp: boolean;
  disableNotification: boolean;
}

export interface UploadedUris {
  compressed: string;
  uncompressed: string;
}

export type TMIconsType = ComponentProps<typeof TMIcon>['name'];

export interface CustomRouteConfig {
  title: string;
  path: string;
  shortTitle?: string;
  icon?: TMIconsType;
  apps?: CustomApp[];
  key: string;
}

export interface InitialRoute {
  route: HomeRoutes;
  path: string;
  isTMProject: boolean;
}

export interface CustomRouter {
  homeRoutes: {
    name: HomeRoutes;
    config: CustomRouteConfig;
  }[];
  menuRoutes: {
    name: HomeRoutes;
    config: CustomRouteConfig;
  }[];
  initialRoutes: Partial<Record<OnboardingStatus | 'default', InitialRoute>>;
}

export enum Currency {
  INR = 'INR',
  USD = 'USD',
  EUR = 'EUR',
}

export const Currencies = ['INR', 'USD', 'EUR'] as const;

export type CurrencyType = (typeof Currencies)[number];

export enum LowerCaseCurrency {
  INR = 'inr',
  USD = 'usd',
  EUR = 'eur',
}

export enum LocalStorageKeys {
  VERIFY_STATUS = 'verifyStatus',
  AFTER_LOGIN_REDIRECT_TO = 'afterLoginRedirectTo',
  REFRESH_TOKEN_KEY = 'token',
}
