import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import type { AppDispatch, RootState } from '../context/store';
import { ROUTES } from '../types/routes';

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const useTimeAgoHook = (time: any) => {
  const locale = 'en';
  moment.updateLocale(locale, {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '%d seconds',
      ss: '%d seconds',
      m: '%d minute',
      mm: '%d minutes',
      h: '%d hour',
      hh: '%d hours',
      d: '%d day',
      dd: '%d days',
      w: '%d week',
      ww: '%d weeks',
      M: '%d month',
      MM: '%d months',
      y: '%d year',
      yy: '%d years',
    },
  });
  const [timeDiff, setTimeDiff] = React.useState(
    moment(time).fromNow(true).split(' '),
  ); // Save the current date to be able to trigger an update

  React.useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setTimeDiff(moment(time).fromNow(true).split(' '));
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, [time]);

  if (timeDiff[1] === 'month' || timeDiff[1] === 'months') {
    return `${parseInt(timeDiff[0], 10) * 4}w`;
  }
  return `${timeDiff[0]}${timeDiff[1].slice(0, 1)}`;
};

const timeScalars = [60, 60, 24, 7, 52];
const timeUnits = ['s', 'm', 'h', 'd', 'w', 'y'] as const;

const getHumanReadableTime = (
  ms: number,
  minUnit: (typeof timeUnits)[number] = 's',
) => {
  let timeScalarIndex = 0;
  let scaledTime = ms;
  let consumedMultiple = 1;

  while (scaledTime > timeScalars[timeScalarIndex]) {
    consumedMultiple *= timeScalars[timeScalarIndex];
    scaledTime /= timeScalars[timeScalarIndex++];
  }

  if (timeScalarIndex < timeUnits.indexOf(minUnit)) {
    return {
      text: `0${minUnit}`,
      value: 0,
    };
  }

  if (scaledTime <= 0) {
    return {
      text: `0${timeUnits[timeScalarIndex]}`,
      value: Math.floor(scaledTime) * consumedMultiple,
    };
  }

  return {
    text: `${Math.floor(scaledTime)}${timeUnits[timeScalarIndex]}`,
    value: Math.floor(scaledTime) * consumedMultiple,
  };
};

export const useCounter = (time: any) => {
  const [diff, setDiff] = React.useState(
    moment(time).diff(moment(), 'seconds'),
  ); // Save the current date to be able to trigger an update

  React.useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDiff(moment(time).diff(moment(), 's'));
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, [time]);

  const first = getHumanReadableTime(diff, 'm');
  const second = getHumanReadableTime(diff - first.value);
  return `${first.text} ${second.text}`;
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface CustomNavigateFunction {
  (
    route: ROUTES,
    /** Data is used to send params that are present in the path */
    data?: Record<string, string>,
    options?: NavigateOptions & {
      /** If true, history will be cleared */
      reset?: boolean;
      search?: string;
    },
  ): void;
  /** To check if can go back in the history */
  canGoBack(): boolean;
  /** To go back in the history, delta is a negative number determining how many pages to go back */
  goBack(delta?: number): void;
}

/** useAppNavigate is a custom hook over useNavigate */
export const useAppNavigate = () => {
  const navigate = useNavigate();
  const onNavigate: CustomNavigateFunction = (
    route: ROUTES,
    data?: Record<string, string>,
    options?: NavigateOptions & {
      search?: string;
      reset?: boolean;
    },
  ) => {
    let urlStr = '';

    if (data) Object.keys(data).forEach((k) => !data[k] && delete data[k]);

    let remainingKeys = Object.keys(data || {});
    const path = route.split('?');

    path[0].split('/').forEach((part) => {
      if (!part) return;

      if (part.startsWith(':')) {
        const key = part.slice(1);

        if (data && data[key]) {
          urlStr += `/${data[key]}`;
          remainingKeys = remainingKeys.filter((k) => k !== key);
        }
      } else {
        urlStr += `/${part}`;
      }
    });

    if (path[1]?.length > 1) {
      urlStr += `?${path[1]}`;
    }

    if (remainingKeys.length > 0 && data) {
      if (!urlStr.includes('?')) {
        urlStr += '?';
      }

      urlStr += `${remainingKeys
        .map((key) => `${key}=${data[key]}`)
        .join('&')}`;
    }

    if (options?.search) {
      if (!urlStr.includes('?')) urlStr += '?';

      urlStr += options.search.replace('?', '&');
    }

    if (options?.reset) {
      // window.history.pushState({ idx: 0 }, '', urlStr);
      navigate(urlStr, { ...options, replace: true });
    } else navigate(urlStr, options);
  };

  onNavigate.canGoBack = (): boolean => {
    if (window.history.state && window.history.state.idx > 0) {
      return true;
    }

    return false;
  };

  onNavigate.goBack = (delta: number = -1): void => {
    if (window.history.state && window.history.state.idx > 0) {
      window.history.go(delta);
    } else {
      navigate(ROUTES.HOME);
    }
  };

  return onNavigate;
};
