import React from 'react';

import { useAppProvider } from '../../context/AppProvider';
import { useAppSelector } from '../../shared/hooks';
import { HomeRoutes } from '../../types/routes';
import Header from './Header';
import HomeHeader from './HomeHeader';

interface Props extends Omit<React.ComponentProps<typeof Header>, 'title'> {
  path: HomeRoutes;
  children?: React.ReactNode;
  title?: string;
}

const DynamicHeader: React.FC<Props> = ({
  path,
  children,
  title,
  ...props
}) => {
  const { getRouteTitle } = useAppProvider();
  const {
    customRouter: { homeRoutes },
  } = useAppSelector((state) => state.app);

  const route = homeRoutes.find((r) => r.name === path);

  const routeTitle = getRouteTitle(path);

  // memoize the home component
  if (route) {
    return (
      <HomeHeader title={title || routeTitle} {...props}>
        {children}
      </HomeHeader>
    );
  }

  return (
    <Header title={title || routeTitle} {...props}>
      {children}
    </Header>
  );
};

export default React.memo(DynamicHeader);
