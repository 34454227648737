import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Button, Form, Input, message } from 'antd';
import ReactFlagsSelect from 'react-flags-select';
import { useLocation, useSearchParams } from 'react-router-dom';

import API from '../../api';
import AnalyticsEventsAPI from '../../api/AnalyticsEventsAPI';
import UserAPI from '../../api/UserAPI';
import Assets from '../../assets';
import tmlogo from '../../assets/svg/tmlogo.svg';
import Loader from '../../components/Loader';
import { useTheme } from '../../context/ThemeProvider';
import showAppError from '../../shared/error';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { emailRegex, rgx10digit } from '../../shared/utils';
import { ROUTES } from '../../types/routes';
import LoginHeader from './LoginHeader';
import LoginOrSignupBottomAction from './LoginOrSignupBottomAction';

interface LocationState {
  phone?: string;
  email?: string;
  mode: 'phone' | 'email';
}

const SignupCreatorBox: React.FC = () => {
  const navigate = useAppNavigate();
  const location = useLocation();

  const { colors: COLORS } = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isTagMango,
    hostMetadata: { logo },
  } = useAppSelector((state) => state.app);

  const [form] = Form.useForm();

  const [saving, setSaving] = useState(false);
  const [countryCode, setCountryCode] = useState('IN');
  const [countryLoading, setCountryLoading] = useState(false);
  const [referNEarnId, setReferNEarnId] = useState('');

  const savePersonalDetails = async (values: any) => {
    if (!values) return;
    let { name, phone, email } = values;
    name = name?.toString().trim() as string;
    email = email?.toString().trim() as string;
    phone = phone?.toString().trim() as string;
    if (!name || !name.length) {
      message.error('Please enter your name');
      return;
    }
    if (!email || !email.length) {
      message.error('Please enter email ID');
      return;
    }
    if (!phone || !phone.length) {
      message.error('Please enter phone number');
      return;
    }
    if (countryCode === 'IN' && !rgx10digit.test(phone)) {
      message.error('Please enter a 10 digit phone number');
      return;
    }
    if (!/^[0-9]*$/.test(phone) || phone.length > 15 || phone.length < 7) {
      message.error('Please enter a valid phone number (7-15 digits)');
      return;
    }
    if (!emailRegex.test(email)) {
      message.error('Please enter valid email Id');
      return;
    }

    setSaving(true);
    UserAPI.registerUser(name, phone, email, countryCode)
      .then((response) => {
        if (response.data.code === 0) {
          navigate(
            ROUTES.VERIFY_OTP,
            {},
            {
              state: {
                ...(location.state || {}),
                name,
                email,
                phone,
                mode: 'email',
                flow: 'signup',
                countryCode,
                referNEarnId,
              },
            },
          );

          // track signup details entered
          AnalyticsEventsAPI.trackSignupEvents('TM_SIGNUP_DETAILS_ENTERED', {
            name,
            email,
            phone,
          });

          message.success('OTP sent successfully.');
        } else {
          showAppError(response.data);
        }
      })
      .catch((err: any) => {
        showAppError(err);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const getCountry = async () => {
    setCountryLoading(true);
    const response = await API.getCurrentCountry();
    if (response.data.code === 0) {
      const data = response?.data?.result;
      setCountryCode(JSON.parse(data as string)?.countryCode);
    }
    setCountryLoading(false);
  };

  useEffect(() => {
    getCountry();

    // track signup page opened
    AnalyticsEventsAPI.trackSignupEvents('TM_SIGNUP_PAGE_OPENED', {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countryLoading) return;
    const state = location.state as LocationState;
    if ((state?.phone || state?.email) && state?.mode) {
      form.setFieldsValue({
        phone: state?.phone,
        email: state?.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryLoading]);

  useEffect(() => {
    if (searchParams.get('rneId')) {
      setReferNEarnId(searchParams.get('rneId') as string);
      setSearchParams((prev) => {
        prev.delete('rneId');
        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const numberExtension = useMemo(
    () => (
      <ReactFlagsSelect
        selected={countryCode}
        onSelect={(selected) => {
          setCountryCode(selected);
        }}
        searchable
        showSelectedLabel={false}
        id="flags-select"
        className="countryFlag"
      />
    ),
    [countryCode],
  );

  return saving || countryLoading ? (
    <Loader />
  ) : (
    <div className="loginBox">
      <LoginHeader
        // title={`Sign up${isTagMango ? ' to TagMango' : ''}`}
        // subtitle="Let’s get you started"
        title="Welcome! First things first..."
        subtitle="You can always change them later."
        logo={isTagMango ? tmlogo : logo}
      />

      <Form
        form={form}
        layout="vertical"
        className="loginForm"
        onFinish={savePersonalDetails}>
        <Form.Item required requiredMark name="name">
          <Input placeholder="Enter full name" className="loginInput" />
        </Form.Item>

        <Form.Item required requiredMark name="email">
          <Input
            type="email"
            placeholder="Enter email"
            className="loginInput"
          />
        </Form.Item>

        <Form.Item required requiredMark name="phone">
          <Input
            addonBefore={numberExtension}
            placeholder="Enter phone number"
            className="loginInputAddon"
          />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          block
          className="loginFormSubmitBtn">
          Get started
        </Button>

        <LoginOrSignupBottomAction action="login" style={{ marginTop: 4 }} />
      </Form>

      <div className="loginBoxFooter">
        <div className="tmSignupAgree">
          By clicking <span>‘Continue’</span> you are agreeing to our{' '}
          <a
            href={Assets.links.termsAndConditionsPage}
            style={{ color: COLORS.BLUE }}>
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href={Assets.links.privacyPolicyPage}
            style={{ color: COLORS.BLUE }}>
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  );
};

export default SignupCreatorBox;
