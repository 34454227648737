import './styles.scss';

import React, { useCallback, useMemo } from 'react';

import { Button, Divider } from 'antd';
import { Helmet } from 'react-helmet';
import { IoChevronForward, IoDiamond } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import TMIcon from 'tm-icons-library';
import { IconName } from 'tm-icons-library/dist/IconTypes';

import Header from '../../components/Header/Header';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import SettingsItem from '../../components/SettingsItem/SettingsItem';
import { useAppProvider } from '../../context/AppProvider';
import { useTheme } from '../../context/ThemeProvider';
import { checkForPermissions } from '../../context/userReducer';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { CHAT_NAME_COLORS } from '../../shared/Styles';
import { tintColor } from '../../shared/utils';
import { TMIconsType } from '../../types/baseTypes';
import { ROUTES } from '../../types/routes';
import { UAMPermissions } from '../../types/userTypes';
import BecomeACreator from './components/BecomeACreator/BecomeACreator';
import ProfileHeader from './components/ProfileHeader';
import PointsPill from './screens/Leaderboard/components/PointsPill/PointsPill';

const Profile: React.FC = () => {
  const { colors } = useTheme();
  const navigate = useAppNavigate();
  const { handleLogout } = useAppProvider();

  const userDetails = useAppSelector((state) => state.user);
  const {
    hostMetadata: { appName, pointsConfig, creator },
    isTagMango,
    isGamificationEnabled,
    customRouter: { homeRoutes, menuRoutes },
  } = useAppSelector((state) => state.app);

  const [logoutModalVisible, setLogoutModalVisible] = React.useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = React.useState(false);

  const logout = async () => {
    setIsLoaderVisible(true);
    handleLogout();
  };

  const checkIfRouteDisabled = useCallback(
    (route: ROUTES) => {
      const onboardingStatus = userDetails.type;
      switch (route) {
        case ROUTES.COURSES:
          return (
            onboardingStatus === 'creator_restricted' &&
            !checkForPermissions(
              [UAMPermissions.COURSE_ACCESS],
              userDetails.userAccessPermissions,
            )
          );

        case ROUTES.MESSAGES:
          return (
            onboardingStatus === 'creator_restricted' &&
            !checkForPermissions(
              [UAMPermissions.MESSAGES_ACCESS],
              userDetails.userAccessPermissions,
            )
          );
        case ROUTES.STOREFRONT:
          return (
            !onboardingStatus ||
            (!['creator_completed', 'dual'].includes(onboardingStatus) &&
              isTagMango)
          );
        case ROUTES.SUBSCRIPTIONS:
          return (
            !onboardingStatus ||
            !['fan_completed', 'dual'].includes(onboardingStatus)
          );

        case ROUTES.LEADERBOARD:
          return !isGamificationEnabled;
        default:
          return false;
      }
    },
    [
      userDetails.type,
      userDetails.userAccessPermissions,
      isTagMango,
      isGamificationEnabled,
    ],
  );

  const shortcurtItems = useMemo(() => {
    const allRoutes = [...homeRoutes, ...menuRoutes];
    const routesToShow: {
      name: ROUTES;
      title: string;
      icon: TMIconsType;
      color: string;
    }[] = [
      // {
      //   name: ROUTES.FEED,
      //   title: 'Feed',
      //   icon: 'compass',
      //   color: CHAT_NAME_COLORS[0],
      // },
      // {
      //   name: ROUTES.VIDEO_CALL,
      //   title: 'Video call',
      //   icon: 'videocam',
      //   color: CHAT_NAME_COLORS[7],
      // },
      // {
      //   name: ROUTES.COURSES,
      //   title: 'Courses',
      //   icon: 'book',
      //   color: CHAT_NAME_COLORS[8],
      // },
      // {
      //   name: ROUTES.MESSAGES,
      //   title: 'Messages',
      //   icon: 'chatbubbles',
      //   color: CHAT_NAME_COLORS[3],
      // },
      {
        name: ROUTES.LEADERBOARD,
        title: 'Leaderboard',
        icon: 'trophy',
        color: CHAT_NAME_COLORS[10],
      },
      // {
      //   name: ROUTES.STOREFRONT,
      //   title: 'Storefront',
      //   icon: 'storefront',
      //   color: CHAT_NAME_COLORS[6],
      // },
      {
        name: ROUTES.SUBSCRIPTIONS,
        title: 'Purchase History',
        icon: 'pricetags',
        color: CHAT_NAME_COLORS[9],
      },
    ];

    return routesToShow
      .filter((route) => !checkIfRouteDisabled(route.name))
      .filter((route) => !allRoutes.find((r) => r.name === route.name))
      .map((route) => (
        <Link key={route.name} to={route.name}>
          <div
            title={route.title}
            className={`profile__shortcut color-[${route.color}]`}
            style={{
              color: route.color,
            }}>
            <TMIcon name={route.icon} color={route.color} size={26} />

            <span>{route.title}</span>
          </div>
        </Link>
      ));
  }, [checkIfRouteDisabled, homeRoutes, menuRoutes]);

  const settingsItems: {
    route?: ROUTES;
    icon: IconName;
    title: string;
    description?: string;
    onPress?: () => void;
  }[] = useMemo(() => {
    return [
      {
        icon: 'person',
        title: 'View profile',
        description: 'View your profile page as others see it',
        onPress: () => {
          navigate(ROUTES.USER_PROFILE, {
            userId: userDetails.id || '',
          });
        },
      },
      {
        route: ROUTES.PERSONAL_INFO,
        icon: 'person-circle',
        title: 'Personal information',
        description: 'Your name, bio, gender, birthday and more',
      },
      // {
      //   route: ROUTES.SUBSCRIPTIONS,
      //   icon: 'pricetags',
      //   title: 'Purchase history',
      //   description: 'View purchases and download invoices',
      // },
      {
        route: ROUTES.ACCOUNT_SETTINGS,
        icon: 'key',
        title: 'Account',
        description: 'Update registered phone number & email',
      },
      {
        route: ROUTES.NOTIFICATION_SETTINGS,
        icon: 'settings',
        title: 'Settings',
        description: 'Manage notifications and messages settings',
      },
      {
        route: ROUTES.HELP_SUPPORT,
        icon: 'help-circle',
        title: 'Help & support',
        description: 'Get help and support',
      },
      // {
      //   title: 'Logout',
      //   icon: 'log-out',
      //   onPress: () => {
      //     setLogoutModalVisible(true);
      //   },
      // },
    ];
  }, [navigate, userDetails.id]);

  const title = `${userDetails.name || 'User'} - Your Profile`;
  const metaTitle = `${
    userDetails.name || 'User'
  }'s profile page on ${appName}`;
  const metaDescription = `${
    userDetails.name || 'User'
  }'s profile page - Edit any changes to your profile page on ${appName}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="tagmango, creator, artist, influencer, educator, trainer, public speaking, learn online, make money online, youtube, Instagram creator, youtube creator"
        />
      </Helmet>

      <div className="profile__container">
        <Header title="Menu" canGoBackToHome />

        <div className="profile__content">
          <ProfileHeader
            firstName={userDetails.name?.split(' ')[0] || ''}
            profileImage={userDetails.profilePic || undefined}
          />

          {/* For subscribers */}
          {isTagMango && userDetails.type === 'fan_completed' && (
            <div
              style={{
                margin: '16px 0',
                padding: '0 12px',
              }}>
              <BecomeACreator />
            </div>
          )}

          {isGamificationEnabled &&
          !(
            creator?._id === userDetails.id ||
            userDetails.type === 'creator_restricted'
          ) ? (
            <SettingsItem
              onClick={() => {
                navigate(ROUTES.POINTS_LEDGER);
              }}
              icon={<IoDiamond color={colors.ICON} />}
              title="Points"
              additionalInfo={
                <>
                  <PointsPill
                    points={userDetails.score?.lifetime || 0}
                    icon={pointsConfig?.pointsImage}
                    name={pointsConfig?.pointsName}
                  />
                  <IoChevronForward
                    size={24}
                    color={colors.ICON}
                    style={{
                      marginRight: '-8px',
                      marginLeft: '8px',
                    }}
                  />
                </>
              }
            />
          ) : null}

          {shortcurtItems.length > 0 && (
            <div className="profile__shortcuts">{shortcurtItems}</div>
          )}

          <Divider />

          <div className="profile__settings">
            {settingsItems.map((item) => (
              <SettingsItem
                key={item.title}
                onClick={
                  item.onPress || (() => item.route && navigate(item.route))
                }
                icon={
                  <TMIcon
                    name={item.icon}
                    size={20}
                    color={
                      item.title === 'Logout' ? colors.DANGER : colors.ICON
                    }
                  />
                }
                title={item.title}
                description={item.description}
                warning={item.title === 'Logout'}
              />
            ))}

            <div className="profile__settings__footer">
              <Button
                className="siteBtn siteBtnPrimary"
                onClick={() => setLogoutModalVisible(true)}
                style={{
                  color: colors.DANGER,
                  backgroundColor: tintColor(colors.DANGER, 90),
                }}
                block>
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        open={logoutModalVisible}
        loading={isLoaderVisible}
        title="Logout"
        message="Are you sure you want to logout?"
        okayButtonText="Logout"
        cancelButtonText="Cancel"
        handleOk={() => {
          logout();
        }}
        handleCancel={() => {
          setLogoutModalVisible(false);
        }}
      />
    </>
  );
};

export default Profile;
