import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Button, Calendar, Divider, message, Typography } from 'antd';
import moment, { Moment } from 'moment-timezone';
import {
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoGlobeOutline,
} from 'react-icons/io5';

import FeedAPI from '../../api/FeedAPI';
import CustomParsedReadMoreContent from '../../components/CustomReadMoreText/CustomParsedReadMoreContent';
import Header from '../../components/Header/Header';
import { onRefresh } from '../../context/activityReducer';
import showAppError from '../../shared/error';
import { useAppDispatch, useAppNavigate } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import MangoTitle from '../Feed/Posts/MangoTitle';
import { IConsultationPendingEvent, IConsultationSlot } from './types';

const DATE_FORMAT = 'YYYY-MM-DD';
const presentDate = moment().startOf('day').format(DATE_FORMAT);

interface Props {
  isModalVisible: boolean;
  event: IConsultationPendingEvent;
  handleBack: () => void;
}

const BookOneToOneConsultation: React.FC<Props> = ({
  isModalVisible,
  event,
  handleBack,
}) => {
  const navigate = useAppNavigate();

  const dispatch = useAppDispatch();

  const { title, description, duration, slots, mango } = event;

  const [selectedDay, setSelectedDay] = useState<string>('');
  const [bookedSlot, setBookedSlot] = useState<IConsultationSlot | null>(null);
  const [isBooking, setIsBooking] = useState<boolean>(false);

  const nextAvailableSlotDate = useMemo(() => {
    if (slots) {
      return moment(
        slots.find((slot) => {
          return moment(slot?.date)
            .startOf('day')
            .isSameOrAfter(moment().startOf('day'));
        })?.date || presentDate,
      ).format(DATE_FORMAT);
    }

    return presentDate;
  }, [slots]);

  const bookSlot = async () => {
    if (bookedSlot) {
      setIsBooking(true);
      try {
        const resp = await FeedAPI.bookConsultationSlot(
          event._id,
          bookedSlot._id,
          moment(bookedSlot.utcStartTime).toISOString(),
        );
        if (resp.status === 200) {
          // navigator.replace(RootRoutesList.WEBINAR_DETAILS, {
          //   webinarId: resp.data.result.videocall,
          // });
          navigate(ROUTES.VIDEO_CALL);
          dispatch(onRefresh());
          message.success('Slot booked successfully');
        } else {
          showAppError(resp.data);
        }
      } catch (err: any) {
        showAppError(err);
      } finally {
        setIsBooking(false);
      }
    } else {
      message.error('Please select a slot to book');
    }
  };

  const handleChangeDate = (date: Moment) => {
    setSelectedDay(date.format(DATE_FORMAT));
  };

  const markedDates = useMemo(() => {
    const dates: Record<string, { selected?: boolean; marked?: boolean }> = {};

    if (selectedDay) {
      dates[selectedDay] = { selected: true };
    }

    if (slots) {
      slots.forEach((slot) => {
        const key = moment(slot.utcStartTime).format(DATE_FORMAT);
        dates[key] = {
          ...(dates[key] || {}),
          marked: true,
        };
      });
    }

    return dates;
  }, [selectedDay, slots]);

  const availableSlots = useMemo(() => {
    let daySlots: IConsultationSlot[] = [];

    if (selectedDay) {
      daySlots = slots.filter((slot) => {
        return moment(slot.utcStartTime).format(DATE_FORMAT) === selectedDay;
      });
    }

    return daySlots;
  }, [selectedDay, slots]);

  const disableDate = (current: Moment) => {
    return current < moment().startOf('day');
  };

  useEffect(() => {
    if (isModalVisible) {
      setSelectedDay(nextAvailableSlotDate);
      setBookedSlot(null);
    }
  }, [isModalVisible, nextAvailableSlotDate]);

  const customMonthHeader = ({
    value,
    onChange,
  }: {
    value: Moment;
    onChange: (value: Moment) => void;
  }) => {
    // this will render month with year along with prev and next button

    const year = value.year();
    const month = value.month();
    const months = moment.months();
    const currentMonth = months[month];
    const currentYear = year;

    const onMonthChange = (newMonth: number) => {
      const now = value.clone().month(newMonth);
      onChange(now);
    };

    return (
      <div className="custom-calendar-header">
        <Button
          onClick={() => onMonthChange(month - 1)}
          className="arrow-btn"
          icon={<IoChevronBackOutline size={24} />}
          disabled={moment(value)
            .startOf('day')
            .subtract(1, 'month')
            .isBefore(moment().startOf('day'))}
        />

        <Typography.Title
          level={5}
          style={{ margin: 0 }}
          className="month-title">
          {currentMonth} {currentYear}
        </Typography.Title>

        <Button
          onClick={() => onMonthChange(month + 1)}
          className="arrow-btn"
          icon={<IoChevronForwardOutline size={24} />}
        />
      </div>
    );
  };

  return (
    <>
      <div className="postdetails__container bookslot__container">
        <Header title="Book a slot" handleBack={handleBack} />

        <div className="scroll__container">
          <h3 className="event-title">{title}</h3>

          <div className="subtitle">
            <MangoTitle mangoes={[mango as any]}>
              <span className="time">{duration} minutes</span>{' '}
              <span className="dot">•</span>{' '}
            </MangoTitle>
          </div>

          {description ? (
            <CustomParsedReadMoreContent
              text={description}
              truncatedLength={200}
              className="mb-0"
            />
          ) : null}

          <Divider className="divider-border" />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBlock: 12,
            }}>
            <h4 className="label">Select date & time</h4>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <IoGlobeOutline />

              <Typography.Text style={{ margin: 0, marginLeft: 6 }}>
                {
                  moment.tz(moment.tz.guess()).zoneAbbr() // get timezone
                }
              </Typography.Text>
            </div>
          </div>

          <Calendar
            fullscreen={false}
            mode="month"
            headerRender={customMonthHeader}
            dateCellRender={(date) => {
              const dateString = date.format(DATE_FORMAT);
              const isPastDate = date.isBefore(moment().startOf('day'));

              if (markedDates[dateString]?.marked) {
                return (
                  <div
                    className="date-active-dot"
                    style={{
                      opacity: isPastDate ? 0.5 : 1,
                      filter: isPastDate ? 'grayscale(1)' : 'none',
                    }}
                  />
                );
              }

              return null;
            }}
            disabledDate={disableDate}
            value={moment(selectedDay)}
            onChange={handleChangeDate}
          />

          {selectedDay ? (
            <>
              <h4
                className="label"
                style={{
                  marginBottom: 12,
                }}>
                Available slots
              </h4>

              {availableSlots.length === 0 ? (
                <Typography.Paragraph className="mb-0">
                  No slots available on{' '}
                  <b style={{ fontWeight: 500 }}>
                    {selectedDay
                      ? moment(selectedDay).format('MMM DD, YYYY')
                      : moment().format('MMM DD, YYYY')}
                    .
                  </b>
                </Typography.Paragraph>
              ) : (
                <div className="slots-list">
                  {availableSlots.map((slot) => {
                    const isSelected =
                      slot._id === bookedSlot?._id &&
                      moment(slot.utcStartTime).format(DATE_FORMAT) ===
                        selectedDay;

                    return (
                      <div
                        key={slot._id}
                        className={`slots-list__item ${
                          isSelected ? 'selected' : ''
                        }`}
                        onClick={() => {
                          if (!isSelected) {
                            setBookedSlot(slot);
                          } else {
                            setBookedSlot(null);
                          }
                        }}>
                        <div className="slot__time">
                          {`${moment(slot.utcStartTime).format(
                            'hh:mm A',
                          )} - ${moment(slot.utcEndTime).format('hh:mm A')}`}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          ) : null}
        </div>

        <div className="bookslot__footer">
          <Button
            className="siteBtn siteBtnPrimary"
            disabled={!bookedSlot}
            onClick={bookSlot}
            loading={isBooking}
            block>
            Book slot
          </Button>
        </div>
      </div>
    </>
  );
};

export default BookOneToOneConsultation;
