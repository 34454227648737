import React, { forwardRef, useImperativeHandle, useState } from 'react';

import FeedAPI from '../../../../api/FeedAPI';
import Loader from '../../../../components/Loader';
import showAppError from '../../../../shared/error';
import { useAppSelector } from '../../../../shared/hooks';
import { ICommentSelected } from '../../../../types/commentTypes';
import PostCommentCard from './PostCommentCard';
import ViewMoreComment from './ViewMoreComment';

interface Props {
  creator: string;
  userId: string;
  name: string;
  profilePic: string;
  content: string;
  liked: boolean;
  likeCount: number;
  replyCount: number;
  createdAt: string;
  commentId: string;
  highlightedCommentId?: string;
  commentImages?: string[];
  toggleLikeComment: () => void;
  selected: ICommentSelected;
  setSelected: (val: ICommentSelected) => void;
  onRemoveComment: (commentId: string) => void;
  onUpdateReplyCount: (newCount: number) => void;
}

interface RefProps {}

const PostComment: React.ForwardRefRenderFunction<RefProps, Props> = (
  {
    creator,
    userId,
    name,
    profilePic,
    content,
    liked,
    likeCount,
    replyCount,
    createdAt,
    commentId,
    highlightedCommentId,
    commentImages = [],
    toggleLikeComment,
    // selected,
    setSelected,
    onRemoveComment,
    onUpdateReplyCount,
  },
  ref,
) => {
  const [remainingPreviousRepliesCount, setRemainingPreviousRepliesCount] =
    useState(0);
  const [remainingNewerRepliesCount, setRemainingNewerRepliesCount] =
    useState(0);
  const [replies, setReplies] = useState<any[]>([]);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const userDetails = useAppSelector((state) => state.user);
  const [isRepliesVisible, setIsRepliesVisible] = useState<boolean>(false);

  // const highlightedCommentIdRef = useRef<string | null>(
  //   highlightedCommentId || null,
  // );
  // const isInitialRender = useRef(true); // Track the initial render

  const getRepliesAfter = async (lastReply?: string) => {
    setLoadingNext(true);
    try {
      const resp = (
        await FeedAPI.getRepliesForComment(commentId, 'new', lastReply)
      ).data;
      if (resp.code === 0) {
        const data = resp.result.result;
        setRemainingNewerRepliesCount(resp.result.remainingCount);
        setReplies((prev) => [...prev, ...data]);
        setLoadingNext(false);
      } else {
        showAppError(resp);
      }
    } catch (err) {
      showAppError(err);
    } finally {
      setLoadingNext(false);
    }
  };

  const getRepliesBefore = async (lastReply?: string) => {
    setLoadingPrevious(true);
    try {
      const resp = (
        await FeedAPI.getRepliesForComment(commentId, 'old', lastReply)
      ).data;
      if (resp.code === 0) {
        const data = resp.result.result;
        setRemainingPreviousRepliesCount(resp.result.remainingCount);
        setReplies((prev) => [...data, ...prev]);
      } else {
        showAppError(resp);
      }
    } catch (err) {
      showAppError(err);
    } finally {
      setLoadingPrevious(false);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      addReply: (reply: any) => {
        setIsRepliesVisible(true);

        if (replyCount > replies.length) {
          setRemainingPreviousRepliesCount(replyCount - replies.length);
        }

        setReplies((prev) => [...prev, reply]);
      },
    }),
    [replies.length, replyCount],
  );

  const toggleRepliesLike = (reply: any) => {
    setReplies((prev) =>
      prev.map((r) => {
        if (r._id === reply._id) {
          const newReply = { ...r };
          newReply.liked = !newReply.liked;
          if (r.liked) {
            newReply.likedBy = r.likedBy.filter(
              (x: any) => x !== userDetails.id,
            );
          } else {
            newReply.likedBy.push(userDetails.id);
          }
          return newReply;
        }
        return r;
      }),
    );
  };

  // useEffect(() => {
  //   const scrollToHighlightedComment = () => {
  //     const commentElement = document.getElementById(
  //       `comment${highlightedCommentIdRef.current}`,
  //     );
  //     const replyElement = document.getElementById(
  //       `reply${highlightedCommentIdRef.current}`,
  //     );

  //     if (commentElement) {
  //       commentElement.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //         inline: 'nearest',
  //       });
  //     } else if (replyElement) {
  //       replyElement.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //         inline: 'nearest',
  //       });
  //     }

  //     // Clear the ref after scrolling
  //     highlightedCommentIdRef.current = null;
  //   };

  //   if (highlightedCommentIdRef.current && isInitialRender.current) {
  //     if (highlightedCommentIdRef.current !== commentId) {
  //       setIsRepliesVisible(true);

  //       const isHighlightedInReplies = replies.some(
  //         (reply) => reply._id === highlightedCommentIdRef.current,
  //       );

  //       if (!isHighlightedInReplies) {
  //         getRepliesBefore();
  //       } else {
  //         scrollToHighlightedComment();
  //       }
  //     } else {
  //       scrollToHighlightedComment();
  //     }

  //     // Mark the initial render as done
  //     isInitialRender.current = false;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [replies, commentId]);

  return (
    <li
      className="postCommentListItem"
      key={commentId}
      id={`comment${commentId}`}>
      <PostCommentCard
        creator={creator}
        commentId={commentId}
        commentImages={commentImages}
        onRemoveComment={onRemoveComment}
        userId={userId}
        profilePic={profilePic}
        name={name}
        // selected={
        //   selected.type === 'comment' && selected.commentId === commentId
        // }
        selected={highlightedCommentId === commentId}
        content={content}
        replyCount={
          !isRepliesVisible && replyCount > 0 ? replyCount : undefined
        }
        onLikeClick={async () => {
          try {
            const res = await FeedAPI.updatecommentLike(commentId);
            if (res.status === 200) {
              toggleLikeComment();
            } else {
              showAppError(res.data);
            }
          } catch (err) {
            showAppError(err);
          }
        }}
        onReplyClick={() => {
          setSelected({
            type: 'comment',
            commentId,
            replyId: null,
            userId,
            userName: name,
          });
        }}
        onRepliesCountClick={() => {
          setIsRepliesVisible(true);
          getRepliesBefore();
        }}
        liked={liked}
        likeCount={likeCount}
        createdAt={createdAt}
        type="comment"
      />

      {isRepliesVisible && (
        <div className="postReplyListWrapper">
          {Boolean(remainingPreviousRepliesCount) && !loadingPrevious && (
            <ViewMoreComment
              title={`View ${remainingPreviousRepliesCount} older replies`}
              onClick={() => {
                setSelected({
                  type: null,
                  commentId: null,
                  replyId: null,
                  userName: null,
                  userId: null,
                });
                if (replies.length > 0) {
                  getRepliesBefore(replies[0]._id);
                } else {
                  getRepliesBefore();
                }
              }}
            />
          )}

          {loadingPrevious && <Loader />}

          <ul className="postReplyist">
            {replies.map((reply) => (
              <li
                key={reply._id}
                className="postReplyListItem"
                id={`reply${reply._id}`}>
                <PostCommentCard
                  creator={creator}
                  onRemoveComment={async (replyId) => {
                    setReplies(replies.filter((rply) => rply._id !== replyId));
                    try {
                      await FeedAPI.deleteComment(replyId);
                      onUpdateReplyCount(replyCount - 1);
                    } catch (err) {
                      showAppError(err);
                    }
                  }}
                  commentId={reply._id}
                  commentImages={reply?.images || []}
                  userId={reply.userId}
                  // selected={selected.replyId === reply._id}
                  selected={highlightedCommentId === reply._id}
                  profilePic={reply.imgUrl || ''}
                  name={reply.userName || ''}
                  content={reply.text || ''}
                  replyCount={undefined}
                  onLikeClick={async () => {
                    try {
                      const res = await FeedAPI.updatecommentLike(reply._id);
                      if (res.status === 200) {
                        toggleRepliesLike(reply);
                      } else {
                        showAppError(res.data);
                      }
                    } catch (err) {
                      showAppError(err);
                    }
                  }}
                  onReplyClick={() => {
                    setSelected({
                      type: 'reply',
                      commentId,
                      replyId: reply._id,
                      userId: reply.userId,
                      userName: reply.userName,
                    });
                  }}
                  liked={!!reply.liked}
                  likeCount={reply.likedBy.length || 0}
                  createdAt={reply.createdAt}
                  noOfLines={2}
                  type="reply"
                />
              </li>
            ))}
          </ul>

          <div>
            {Boolean(remainingNewerRepliesCount) && !loadingNext && (
              <ViewMoreComment
                onClick={() => {
                  setSelected({
                    type: null,
                    commentId: null,
                    replyId: null,
                    userName: null,
                    userId: null,
                  });
                  if (replies.length > 0) {
                    getRepliesAfter(replies[replies.length - 1]._id);
                  } else {
                    getRepliesAfter();
                  }
                }}
                title={`View ${remainingNewerRepliesCount} newer replies`}
              />
            )}

            {loadingNext && <Loader />}
          </div>
        </div>
      )}
    </li>
  );
};

export default forwardRef(PostComment);
