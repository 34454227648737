import WhatsAppLightBg from './img/WhatsApp-light-bg.png';

const Assets = {
  images: {
    WhatsAppLightBg,
  },
  links: {
    termsAndConditionsPage: 'https://tagmango.com/terms',
    privacyPolicyPage: 'https://tagmango.com/privacy-policy',
  },
};

export default Assets;
