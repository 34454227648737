import './CustomParsedReadMoreContent.scss';

import React, { memo, useState } from 'react';

import { Typography } from 'antd';
import ReactHtmlParser from 'html-react-parser';
import Linkify from 'react-linkify';

interface Props {
  text: string;
  truncatedLength?: number;
  seeMoreText?: string;
  seeLessText?: string;
  className?: string;
  style?: React.CSSProperties;
}

const CustomParsedReadMoreContent: React.FC<Props> = ({
  text,
  truncatedLength = 660,
  seeMoreText = 'See more',
  seeLessText = 'See less',
  className = '',
  style = {},
}) => {
  const [expanded, setExpanded] = useState(false);

  // Function to replace newlines with <br> for rendering
  const formatContent = (content: string) => {
    // return ReactHtmlParser(content.replaceAll('\n', '<br>'));
    return ReactHtmlParser(content);
  };

  return (
    <Linkify>
      <Typography.Paragraph
        className={`parsed-content ${className}`}
        style={{ marginBottom: 0, ...style }}>
        {text?.length && text.length <= truncatedLength
          ? formatContent(text)
          : null}

        {text?.length && text.length > truncatedLength && !expanded ? (
          <div>
            <div className="parsed-content__text--fading">
              {formatContent(text.slice(0, truncatedLength))}
            </div>
            <div
              style={{ cursor: 'pointer', fontWeight: 600 }}
              tabIndex={0}
              role="button"
              onClick={() => setExpanded(true)}>
              <span className="parsed-content__action-btn">
                ...{seeMoreText}
              </span>
            </div>
          </div>
        ) : null}

        {text?.length && text.length > truncatedLength && expanded ? (
          <div>
            {formatContent(text)}
            <div
              tabIndex={0}
              role="button"
              style={{ cursor: 'pointer' }}
              onClick={() => setExpanded(false)}>
              <span className="parsed-content__action-btn">{seeLessText}</span>
            </div>
          </div>
        ) : null}
      </Typography.Paragraph>
    </Linkify>
  );
};

export default memo(CustomParsedReadMoreContent);
