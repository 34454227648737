import './styles.scss';

import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import CustomAppPreview from './CustomAppPreview';
import CustomApps from './CustomApps';

const CustomAppsWrapper: React.FC = () => {
  const { customRouter } = useAppSelector((state) => state.app);

  const { key } = useParams<{ key: string }>();

  const searchParams = new URLSearchParams(window.location.search);
  // const appIndex = searchParams.get('app');
  const appKey = searchParams.get('app');

  const route = useMemo(
    () =>
      [...customRouter.homeRoutes, ...customRouter.menuRoutes].find(
        (r) => r.config.key === key,
      ),
    [customRouter.homeRoutes, customRouter.menuRoutes, key],
  );

  if (route && route.config.apps) {
    const customApp = route.config.apps.find((app) => app.key === appKey);

    // if (appIndex && parseInt(appIndex, 10) < route.config.apps.length) {
    //   return (
    //     <CustomAppPreview
    //       key={route.config.key}
    //       name={route.name}
    //       app={route.config.apps[parseInt(appIndex, 10)]}
    //     />
    //   );
    // }

    if (appKey && customApp) {
      return (
        <CustomAppPreview
          key={route.config.key}
          name={route.name}
          app={customApp}
        />
      );
    } else if (route.config.apps.length === 1) {
      return (
        <CustomAppPreview
          key={route.config.key}
          name={route.name}
          app={route.config.apps[0]}
        />
      );
    } else {
      return (
        <CustomApps
          key={route.config.key}
          name={route.name}
          route={route.config}
        />
      );
    }
  } else {
    window.location.href = ROUTES.HOME;
  }

  return null;
};

export default CustomAppsWrapper;
