import { useEffect, useMemo } from 'react';

import { XMLParser, XMLValidator } from 'fast-xml-parser';
import TMIcon from 'tm-icons-library';

import Assets from '../../../assets';
import Header from '../../../components/Header/Header';
import SettingsItem from '../../../components/SettingsItem/SettingsItem';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';

const ICON_SIZE = 20;

const title = 'Help & Support';

const HelpSupport: React.FC = () => {
  const { colors } = useTheme();
  const {
    hostMetadata: { customSupportLink, supportWidget },
    isTagMango,
  } = useAppSelector((state) => state.app);

  const supportItem = useMemo(() => {
    if (supportWidget && supportWidget.length > 0) {
      let { icon, onClick } = {
        icon: (
          <TMIcon name="help-circle" size={ICON_SIZE} color={colors.ICON} />
        ),
        onClick: () => {
          window.open('https://help.tagmango.com/hc/en-us', '_blank');
        },
      };

      switch (supportWidget[0].type) {
        case 'email':
          icon = <TMIcon name="mail" size={ICON_SIZE} color={colors.ICON} />;
          onClick = () => {
            window.open(`mailto:${supportWidget[0].value}`, '_blank');
          };
          break;
        case 'whatsapp':
          icon = (
            <TMIcon name="logo-whatsapp" size={ICON_SIZE} color={colors.ICON} />
          );
          onClick = () => {
            window.open(supportWidget[0].value, '_blank');
          };
          break;
        case 'customScript':
          return null;
      }

      return <SettingsItem onClick={onClick} icon={icon} title="Support" />;
    }

    if (isTagMango || customSupportLink) {
      return (
        <SettingsItem
          onClick={() => {
            window.open(
              customSupportLink || 'https://help.tagmango.com/hc/en-us',
              '_blank',
            );
          }}
          icon={
            <TMIcon name="help-circle" size={ICON_SIZE} color={colors.ICON} />
          }
          title="Support"
        />
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportWidget, isTagMango, customSupportLink, colors.ICON]);

  useEffect(() => {
    const supportScript =
      supportWidget &&
      supportWidget.length > 0 &&
      supportWidget[0].type === 'customScript'
        ? supportWidget[0].value
        : null;
    const scripts: any = [];

    if (supportScript) {
      const XMLOptions = {
        allowBooleanAttributes: true,
        ignoreAttributes: false,
        alwaysCreateTextNode: true,
      };
      const parser = new XMLParser(XMLOptions);
      const validationData = XMLValidator.validate(supportScript, XMLOptions);

      if (validationData !== true) {
        return;
      }

      const parsedCode = parser.parse(supportScript);

      const getElementFromData = (data: any, tag: any) => {
        const createdElem = document.createElement(tag);

        Object.keys(data).forEach((key) => {
          if (key.startsWith('#')) {
            createdElem.innerHTML = data[key];
          } else if (key.startsWith('@_')) {
            createdElem.setAttribute(key.replace('@_', ''), data[key]);
            // createdElem[key.replace('@_', '')] = data[key];
          }
        });

        return createdElem;
      };

      Object.keys(parsedCode).forEach((tag) => {
        const value = parsedCode[tag];

        if (Array.isArray(value)) {
          value
            .map((elemData) => getElementFromData(elemData, tag))
            .forEach((sc) => {
              scripts.push(sc);
              document.body.appendChild(sc);
            });
        } else {
          const sc = getElementFromData(value, tag);
          scripts.push(sc);
          document.body.appendChild(sc);
        }
      });
    }

    return () => scripts.forEach((scp: any) => document.body.removeChild(scp));
  }, [supportWidget]);

  return (
    <div className="pageRoot">
      <Header title={title} />

      <div className="pageContent">
        {supportItem}

        <SettingsItem
          onClick={() => {
            window.open(Assets.links.termsAndConditionsPage, '_blank');
          }}
          icon={
            <TMIcon name="document-text" size={ICON_SIZE} color={colors.ICON} />
          }
          title="Terms of Use"
        />

        <SettingsItem
          onClick={() => {
            window.open(Assets.links.privacyPolicyPage, '_blank');
          }}
          icon={
            <TMIcon
              name="shield-checkmark"
              size={ICON_SIZE}
              color={colors.ICON}
            />
          }
          title="Privacy Policy"
        />
      </div>
    </div>
  );
};

export default HelpSupport;
