import './styles.scss';

import React from 'react';

import { useTheme } from '../../context/ThemeProvider';
import { tintColor } from '../../shared/utils';

interface Props {
  icon: React.ReactNode | string;
  title: string;
  description?: string;
  alignIcon?: 'center' | 'flex-start';
  onClick: () => void;
  warning?: boolean;
  color?: string;
  additionalInfo?: React.ReactNode;
}

const SettingsItem: React.FC<Props> = ({
  icon,
  title,
  description,
  alignIcon = 'center',
  onClick,
  warning = false,
  color,
  additionalInfo,
}) => {
  const { colors } = useTheme();
  return (
    <li className="settingsItem" onClick={onClick}>
      <div className="settingsItem__inner" style={{ alignItems: alignIcon }}>
        <div
          className="icon"
          style={{
            backgroundColor: warning
              ? tintColor(colors.DANGER, 90)
              : color
              ? tintColor(color, 90)
              : colors.TERTIARY,
          }}>
          {icon && typeof icon === 'string' ? <img src={icon} alt="" /> : icon}
        </div>
        <div className="details">
          <h3
            className="title"
            style={{ color: warning ? colors.DANGER : color || colors.TEXT1 }}>
            {title}
          </h3>
          {description ? <p className="subtitle">{description}</p> : null}
        </div>
      </div>
      {additionalInfo && (
        <div className="settingsItem__additionalInfo">{additionalInfo}</div>
      )}
    </li>
  );
};

export default React.memo(SettingsItem);
