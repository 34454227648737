/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from 'react';

import { Button, Modal, Tooltip } from 'antd';
import moment from 'moment';
import { MdOutlineInfo } from 'react-icons/md';
import TMIcon from 'tm-icons-library';

import AnalyticsEventsAPI from '../../../api/AnalyticsEventsAPI';
import CloseModal from '../../../assets/svg/CloseModal';
import CopyLinkSvg from '../../../assets/svg/CopyLink';
import CustomParsedReadMoreContent from '../../../components/CustomReadMoreText/CustomParsedReadMoreContent';
import { useAppProvider } from '../../../context/AppProvider';
import { useTheme } from '../../../context/ThemeProvider';
import { useAppSelector } from '../../../shared/hooks';
import { copyToClipboard } from '../../../shared/utils';
import { IWebinar, IWebinarCreatorDetails } from '../../../types/feedTypes';
import { ROUTES } from '../../../types/routes';
import Time from './Time';

interface Props {
  data: IWebinar;
  host: IWebinarCreatorDetails;
  webinarType?: 'upcoming' | 'completed';
  onJoinBtnClick: (data: IWebinar) => void;
  showModal: boolean;
  showCancelModal?: () => void;
  handleCopyLink: () => void;
  handleEditWorkshop?: () => void;
  addToCalendar: () => void;
  closeModal: () => void;
}

const modalMaskStyle: React.CSSProperties = {
  backgroundColor: 'rgba(244, 244, 244, 0.8)',
};

const VideoCallDetails: React.FC<Props> = ({
  data,
  host,
  showModal,
  // handleCopyLink,
  addToCalendar,
  closeModal,
  webinarType = 'upcoming',
  onJoinBtnClick,
}) => {
  const { colors } = useTheme();

  const { getMeetingJoinStatus } = useAppProvider();

  const userDetails = useAppSelector((state) => state.user);
  const { deeplinkUrl } = useAppSelector((state) => state.app);

  const [showJoinBtn, setShowJoinBtn] = React.useState(false);
  const [meetingStarted, setMeetingStarted] = React.useState(false);
  // const [isToggleClicked, setIsToggleClicked] = React.useState(false);

  const typeTitle = useMemo(() => {
    if (data?.consultation) {
      return 'video call';
    }

    return 'workshop';
  }, [data?.consultation]);

  const isJoinVideoCallAllowed = useMemo(
    () => data && getMeetingJoinStatus?.(data) === 'active',
    [data, getMeetingJoinStatus],
  );

  const onMeetingClick = () => onJoinBtnClick(data);

  const timeDiffinMin = () => {
    const fromTime = data?.fromTime;
    if (fromTime) {
      const now = moment(new Date());
      const end = moment(fromTime);
      return moment.duration(end.diff(now)).asMinutes();
    }
    return 20;
  };

  const joinStatus = () => {
    if (moment(data?.toTime).isBefore(moment())) {
      setShowJoinBtn(false);
    } else if (data?.creator === userDetails.id) {
      if (timeDiffinMin() > 30) {
        setTimeout(() => {
          joinStatus();
        }, (timeDiffinMin() - 30) * 60000);
        setShowJoinBtn(false);
      } else {
        setShowJoinBtn(true);
      }
    } else if (timeDiffinMin() > 15) {
      setTimeout(() => {
        joinStatus();
      }, (timeDiffinMin() - 15) * 60000);
      setShowJoinBtn(false);
    } else {
      setShowJoinBtn(true);
    }
  };

  useEffect(() => {
    joinStatus();
    if (
      webinarType === 'upcoming' &&
      moment(data.fromTime).isSameOrAfter(moment())
    ) {
      setTimeout(() => {
        setMeetingStarted(true);
      }, timeDiffinMin() * 60000);
    } else if (
      webinarType === 'upcoming' &&
      moment(data.fromTime).isBefore(moment())
    ) {
      setMeetingStarted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const workshopModalFooter = (
    <div className="workshopModalFooter">
      {!showJoinBtn ? (
        <div className="workshopModalInfoText">
          <MdOutlineInfo
            color={colors.BLUE}
            size={12}
            style={{ marginRight: 4 }}
          />
          Joining link will be activated{' '}
          {data?.creator === userDetails.id ? '30' : '15'} mins prior to the{' '}
          {typeTitle}
        </div>
      ) : (
        <div className="workshopModalTimer">
          {meetingStarted ? 'Ends' : 'Starts'} In:{' '}
          <Time time={meetingStarted ? data.toTime : data.fromTime} />
        </div>
      )}

      <div className="workshopModalFooterBtnGroup">
        {/* <Button
          className="siteBtn noStyle blueBtnV2 joinBtn"
          style={{ width: '100%' }}
          icon={
            <JoinSvg
              width={20}
              height={20}
              background={colors.BACKGROUND}
              foreground={colors.BLUE}
              style={{ marginRight: 12 }}
            />
          }
          onClick={() => onJoinBtnClick(data)}>
          JOIN
        </Button> */}

        <span
          onClick={!isJoinVideoCallAllowed ? onMeetingClick : undefined}
          style={{ width: '100%' }}>
          <Button
            className="siteBtn noStyle blueBtnV2 joinBtn"
            icon={<TMIcon name="videocam" size={20} color={colors.LINK} />}
            onClick={isJoinVideoCallAllowed ? onMeetingClick : undefined}
            disabled={!isJoinVideoCallAllowed}
            block
            style={
              !isJoinVideoCallAllowed
                ? {
                    pointerEvents: 'none',
                  }
                : {}
            }>
            JOIN
          </Button>
        </span>

        {userDetails.id === host._id && !data.consultation ? (
          <>
            <Tooltip
              title={`Copy & share meeting link to join this ${typeTitle}`}
              placement="bottom"
              overlayClassName="workspaceTooltip">
              <Button
                className="siteBtn noStyle grayBtn moreBtnTrigger"
                onClick={() => {
                  copyToClipboard(
                    `${deeplinkUrl}web${ROUTES.WEBINAR_METADATA_VIEW.replace(
                      ':webinarId',
                      data._id,
                    )}`,
                  );

                  // track copy meeting link event
                  AnalyticsEventsAPI.trackWorkshopEvents(
                    'COPY_SUB_LINK_CLICKED',
                    {
                      meetingId: data._id,
                      date: new Date().toISOString(),
                      title: data.title,
                      platform: data.platform,
                      createdAt: data.createdAt,
                      fromTime: data.fromTime,
                      toTime: data.toTime,
                    },
                  );
                }}>
                <CopyLinkSvg />
              </Button>
            </Tooltip>

            {/* <Button
              className={`siteBtn noStyle grayBtn moreBtnTrigger ${
                isToggleClicked && 'active'
              }`}
              onClick={() => setIsToggleClicked((prev) => !prev)}>
              <ArrowUp />
            </Button> */}
          </>
        ) : null}
      </div>

      {/* <div className={`moreBtnGroup ${isToggleClicked && 'active'}`}>
        {moment(data?.fromTime).diff(moment()) > 0 ? (
          <Button
            className="siteBtn noStyle grayBtn"
            block
            onClick={handleEditWorkshop}>
            Edit workshop
          </Button>
        ) : null}
        <Button
          className="siteBtn noStyle grayBtn"
          block
          onClick={() => {
            showCancelModal();
          }}>
          Cancel workshop
        </Button>
      </div> */}
    </div>
  );

  // useEffect(() => {
  //   setIsToggleClicked(false);
  // }, [showModal]);

  return (
    <Modal
      open={showModal}
      onOk={closeModal}
      onCancel={closeModal}
      footer={workshopModalFooter}
      wrapClassName="workshopDetailsModal"
      maskStyle={modalMaskStyle}
      centered
      closeIcon={<CloseModal />}
      width={475}>
      {/* <div className="workshopModalCover">
          <img src="https://picsum.photos/1600/900" alt="" />
        </div> */}
      <div className="workshopModalBody">
        <h4 className="workModalTitle">
          {data?.title}
          <span>
            by<strong>{host?.name}</strong>
          </span>
        </h4>
        <div className="workshopModalTimeWrapper">
          <div className="dateWrapper">
            <span className="month">
              {moment(data?.fromTime).format('MMM')}
            </span>
            <span className="date">{moment(data?.fromTime).format('DD')}</span>
          </div>
          <div className="workshopModalTimeDetails">
            {moment(data?.fromTime).format('MMM Do YY') ===
            moment().format('MMM Do YY') ? (
              <span className="day">Today</span>
            ) : (
              <span className="day">
                {moment(data?.fromTime).format('ddd')}
              </span>
            )}
            <span className="time">
              {moment(data?.fromTime).format('hh:mm A')} -{' '}
              {moment(data?.toTime).format('hh:mm A')}
            </span>
          </div>
          <Button
            className="siteBtn noStyle calendarBtn"
            onClick={addToCalendar}>
            <TMIcon name="videocam" size={24} color={colors.BLUE} />
            {/* <Scheduler height={34} width={24} theme={colors.BLUE} /> */}
          </Button>
        </div>
        {data?.description && (
          <>
            <div className="descriptionTitle">Description</div>
            <CustomParsedReadMoreContent
              className="workshopModalDescription"
              text={data?.description}
              truncatedLength={200}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(VideoCallDetails);
