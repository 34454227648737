import React, { memo, useMemo } from 'react';

import { Typography } from 'antd';

import { ICoursesState } from '../../types/courseTypes';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';

interface Props {
  userId: string | null;
  state: ICoursesState;
}

const NoCoursesContent: React.FC<Props> = ({ userId, state }) => {
  const noCoursesContent = useMemo(() => {
    {
      if (state.loading || !userId || state.hasMore) {
        return null;
        // return (
        //   <Loader
        //     style={{
        //       margin: '20px 0',
        //       width: '100%',
        //     }}
        //   />
        // );
      }

      if (state.courses.length === 0) {
        return <NoDataFound title="Whoops! No courses found!" />;
      }

      return (
        <Typography.Paragraph
          style={{
            width: '100%',
            textAlign: 'center',
            margin: '20px 0',
          }}>
          That’s it! No more courses to show.
        </Typography.Paragraph>
      );
    }
  }, [state.courses.length, state.hasMore, state.loading, userId]);

  return noCoursesContent;
};

export default memo(NoCoursesContent);
