import React, { useEffect, useRef, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroller';

import CourseAPI from '../../api/CourseAPI';
import SearchBar from '../../components/Header/SearchBar';
import Loader from '../../components/Loader';
import { useTheme } from '../../context/ThemeProvider';
import { COURSES_PAGE_LIMIT } from '../../shared/constants';
import showAppError from '../../shared/error';
import { useAppNavigate } from '../../shared/hooks';
import { ICoursesState } from '../../types/courseTypes';
import { ROUTES } from '../../types/routes';
import CourseListCard from './CourseListCard/CourseListCard';
import NoCoursesContent from './NoCoursesContent';

const initialState: ICoursesState = {
  loading: false,
  hasMore: true,
  courses: [],
  page: 1,
  limit: COURSES_PAGE_LIMIT,
};

interface Props {
  userId: string | null;
}

const MyCourses: React.FC<Props> = ({ userId }) => {
  const navigate = useAppNavigate();

  const { colors } = useTheme();

  const [state, setState] = useState<ICoursesState>(initialState);
  const [searchQuery, setSearchQuery] = useState('');

  const scrollContainerRef = useRef<HTMLElement | null>(null);

  const loadCourses = async (
    page: number = 1,
    currState: ICoursesState = state,
  ) => {
    if (!userId || currState.loading || !currState.hasMore) return;

    const errorMsg = 'Failed to load courses.';

    const newState = { ...initialState, ...currState };

    if (page === 1) {
      newState.courses = [];
      newState.page = 1;
      newState.hasMore = true;
    }

    newState.loading = true;

    setState({ ...newState });

    try {
      const resp = await CourseAPI.getCourses({
        page,
        limit: newState.limit,
        creator: userId,
        search: searchQuery,
      });
      if (resp.status === 200) {
        const newCourses = [...resp.data.result];

        newState.courses = [...newState.courses, ...newCourses];

        newState.hasMore = newCourses.length >= newState.limit;

        if (newState.hasMore) {
          newState.page += 1;
        }
      } else {
        showAppError(resp.data, errorMsg);
      }
    } catch (error: any) {
      showAppError(error, errorMsg);
    } finally {
      newState.loading = false;
      setState({ ...newState });
    }
  };

  useEffect(() => {
    loadCourses(1, initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, userId]);

  useEffect(() => {
    const element = document.getElementById('courses__content');

    if (element) {
      scrollContainerRef.current = element;
    }
  }, []);

  return (
    <>
      <SearchBar
        onDebounce={(value) => {
          setSearchQuery(value);
        }}
        placeholder="Search"
        style={{
          width: '100%',
          borderBottomWidth: 1,
          borderColor: colors.BORDER,
        }}
      />

      <div className="courses__content">
        <InfiniteScroll
          hasMore={state.hasMore}
          loadMore={() => loadCourses(state.page)}
          useWindow={false}
          getScrollParent={() => scrollContainerRef.current as HTMLElement}
          loader={
            (state.loading || !userId || state.hasMore ? (
              <Loader
                style={{
                  margin: '20px 0',
                  width: '100%',
                }}
              />
            ) : undefined) as JSX.Element
          }>
          {state.courses.length > 0
            ? state.courses.map((item) => (
                <CourseListCard
                  key={item._id}
                  onPress={() => {
                    navigate(ROUTES.COURSE_OVERVIEW, {
                      courseId: item._id,
                    });
                  }}
                  type="my"
                  title={item.title}
                  coverImage={item.coverImage}
                  mangoes={item.mangoArr}
                />
              ))
            : null}
        </InfiniteScroll>

        <NoCoursesContent userId={userId} state={state} />
      </div>
    </>
  );
};

export default MyCourses;
